html,
body {
  height: 100%;
  min-height: 100%;
}
main {
  min-height: calc(100% - var(--footer-overlap));
  max-width: 925px;
  margin: 0 auto;
  padding: var(--navbar-height) var(--spacing) 0;
  display: flex;
  flex-direction: column;
}
