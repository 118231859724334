.container {
  position: relative;
  margin-top: var(--spacing-xl);
  padding-top: var(--footer-waves-height);
}

.container::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: var(--footer-waves-height);
  background-color: var(--carbon);
  mask-image: url('/icons/waves.svg');
  -webkit-mask-image: url('/icons/waves.svg');
}

.upper_section {
  display: flex;
  flex-direction: column;
  color: var(--barley-50-percent);
  background: var(--carbon);
  padding: var(--spacing-xl);
  gap: var(--spacing-xl);
}

.logo_wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: var(--spacing-sm);
}

.contact_channels {
  display: flex;
  justify-content: center;
}

.navigation {
  display: none;
}

.lower_section {
  background: var(--pitch-black);
  color: var(--barley-50-percent);
  display: grid;
  padding: var(--spacing-xl) var(--spacing);
  grid-gap: var(--spacing);
  grid-template-areas:
    'payment-methods'
    'legal'
    'rights-reserved';
}

.payment_methods {
  grid-area: payment-methods;
  height: 22px;
  display: flex;
  justify-content: center;
  gap: var(--spacing-xs);
}

.legal {
  grid-area: legal;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: var(--spacing);
  text-decoration: underline;
}

.rights_reserved {
  grid-area: rights-reserved;
  text-align: center;
  text-transform: uppercase;
  font-weight: 400;
  font-size: 12px;
  margin: auto 0;
}

@media screen and (min-width: 600px) {
  .lower_section {
    padding: var(--spacing);
    justify-content: space-evenly;
    grid-template-areas:
      'legal payment-methods'
      'rights-reserved payment-methods';
  }

  .payment_methods {
    margin-block: auto;
  }
}

@media screen and (min-width: 920px) {
  .upper_section {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
  }

  .contact_channels {
    margin-block: auto;
    border-left: 1px solid var(--barley-50-percent);
    border-right: 1px solid var(--barley-50-percent);
  }

  .navigation {
    display: unset;
    margin-block: auto;
  }

  .lower_section {
    grid-template-areas: 'legal payment-methods rights-reserved';
  }
}
