.navbar {
  background: var(--carbon);
  color: var(--barley-50-percent);
  width: 100%;
  height: var(--navbar-height);
  padding: 0 var(--spacing);
  position: fixed;
  z-index: var(--z-navbar);
  display: grid;
  grid-template-columns: repeat(3, 1fr);
}

.menu_trigger {
  width: 24px;
  height: 24px;
  margin-block: auto;
  position: relative;
}

.menu_trigger::after {
  content: '';
  width: 24px;
  height: 24px;
  background: url(/icons/menu.svg) center no-repeat;
  position: absolute;
  top: 0;
  left: 0;
}

.menu_trigger[aria-expanded='true']:after {
  background: currentColor;
  mask: url(/icons/close.svg) center no-repeat;
}

.mobile_container {
  height: 100%;
  position: fixed;
  left: 0;
  right: 0;
  top: -100%;
  padding: calc(var(--navbar-height) + var(--spacing)) var(--spacing)
    var(--spacing-xl);
  z-index: calc(var(--z-navbar) - 1);
  visibility: hidden;
  background: var(--carbon);
  transition:
    top 0.4s ease,
    visibility 0s 1s;
  overflow-x: auto;
  overflow-y: scroll;
  color: var(--barley-50-percent);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  gap: 2rem;
}

.mobile_container_open {
  top: 0;
  visibility: visible;
  transition:
    top 0.4s ease,
    visibility 0s;
}

.link_list_mobile {
  gap: var(--spacing-xl);
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow-y: scroll;
}

.link_list_desktop {
  display: none;
}

.logo_link {
  display: flex;
  justify-content: center;
}
.nav_item {
  font: var(--h3);
}

.nav_item:hover {
  transition: 250ms ease-in;
  text-decoration: underline;
}

.divider {
  width: 100%;
  border-top: 1px solid var(--barley-50-percent);
}

@media (min-width: 600px) {
  .mobile_container {
    height: unset;
    text-align: left;
    align-items: flex-start;
    padding-inline: var(--spacing);
    align-items: center;
  }

  .link_list_mobile {
    display: unset;
    width: 100%;
    columns: 2;
    width: fit-content;
    column-gap: var(--spacing-lg);
  }
}

@media only screen and (min-width: 920px) {
  .navbar {
    display: flex;
    justify-content: space-between;
  }

  .menu_trigger {
    display: none;
  }

  .mobile_container {
    display: none;
  }

  .link_list_desktop {
    display: flex;
    align-items: center;
    gap: var(--spacing);
  }

  .nav_item {
    font: var(--p);
  }
}
